import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Layout from '@components/layouts/default/Layout'
import GeneratorHeaderSection from '@sections/free-ai-tools-generators/GeneratorHeaderSection'
import valuePropositionGeneratorIcon from '@assets/images/free-ai-tools/valuePropositionGenerator.svg'
import GeneratorTextSection from '@sections/free-ai-tools-generators/GeneratorTextSection'
import GeneratorCTASection from '@sections/free-ai-tools-generators/GeneratorCTASection'
import GeneratorFAQSection from '@sections/free-ai-tools-generators/GeneratorFAQSection'
import ValuePropositionGeneratorFormSection from '@sections/free-ai-tools-generators/value-proposition-generator/ValuePropositionGeneratorFormSection'

const ValuePropositionGenerator = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allValuePropositionGeneratorFaqJson {
        edges {
          node {
            key
            head
            body
          }
        }
      }
    }
  `)
  return (
    <Layout pageName="value-proposition-generator">
      <GeneratorHeaderSection
        titleIcon={valuePropositionGeneratorIcon}
        title={intl.formatMessage({ id: 'free-ai-tools-pages.value-proposition-generator.title' })}
        description={intl.formatMessage({ id: 'free-ai-tools-pages.value-proposition-generator.subtitle' })}
      />

      <ValuePropositionGeneratorFormSection />
      <GeneratorTextSection
        title={intl.formatMessage({ id: 'free-ai-tools-pages.value-proposition-generator.section01.title' })}
        description={[
          intl.formatMessage({ id: 'free-ai-tools-pages.value-proposition-generator.section01.description01' }),
          intl.formatMessage({ id: 'free-ai-tools-pages.value-proposition-generator.section01.description02' }),
          intl.formatMessage({ id: 'free-ai-tools-pages.value-proposition-generator.section01.description03' }),
        ]}
      />
      <GeneratorTextSection
        title={intl.formatMessage({ id: 'free-ai-tools-pages.value-proposition-generator.section02.title' })}
        description={[
          intl.formatMessage({ id: 'free-ai-tools-pages.value-proposition-generator.section02.description01' }),
        ]}
        list={[
          {
            firstPart: intl.formatMessage({
              id: 'free-ai-tools-pages.value-proposition-generator.section02.list.item01.bold-text',
            }),
            secondPart: intl.formatMessage({
              id: 'free-ai-tools-pages.value-proposition-generator.section02.list.item01.regular-text',
            }),
          },
          {
            firstPart: intl.formatMessage({
              id: 'free-ai-tools-pages.value-proposition-generator.section02.list.item02.bold-text',
            }),
            secondPart: intl.formatMessage({
              id: 'free-ai-tools-pages.value-proposition-generator.section02.list.item02.regular-text',
            }),
          },
          {
            firstPart: intl.formatMessage({
              id: 'free-ai-tools-pages.value-proposition-generator.section02.list.item03.bold-text',
            }),
            secondPart: intl.formatMessage({
              id: 'free-ai-tools-pages.value-proposition-generator.section02.list.item03.regular-text',
            }),
          },
        ]}
      />
      <GeneratorFAQSection faqData={data.allValuePropositionGeneratorFaqJson} />
      <GeneratorCTASection
        title={intl.formatMessage({ id: 'free-ai-tools-pages.value-proposition-generator.sectionCTA.title' })}
        description={intl.formatMessage({ id: 'free-ai-tools-pages.value-proposition-generator.sectionCTA.subtitle' })}
        buttonText={intl.formatMessage({ id: 'free-ai-tools-pages.value-proposition-generator.sectionCTA.buttonText' })}
      />
    </Layout>
  )
}

export default injectIntl(ValuePropositionGenerator)
