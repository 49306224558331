import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import FreeAiToolsFormWrapper from '@components/pages/free-ai-tools/FreeAiToolsFormWrapper'
import ValuePropositionGeneratorForm from '@components/pages/free-ai-tools/forms/ValuePropositionGeneratorForm'

const ValuePropositionGeneratorFormSection = ({ intl }) => {
  return (
    <FreeAiToolsFormWrapper
      title={intl.formatMessage({ id: 'free-ai-tools-pages.value-proposition-generator.form.title' })}
    >
      <ValuePropositionGeneratorForm intl={intl} />
    </FreeAiToolsFormWrapper>
  )
}

export default injectIntl(ValuePropositionGeneratorFormSection)
